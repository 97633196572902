.search {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-bottom: 2rem;
}

.form {
	display: flex;
}
