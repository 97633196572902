:root {
	--color-blue-primary: #172b6f;
	--color-deep-navy: #172b6f;
	--color-orange-primary: #ff9900;

	--color-blue: #156082;
	--color-blue-secondary: #156fae;
	--color-blue-link: #1677ff;
	--color-blue-link-hover: #69b1ff;

	--color-gray: #808080;
	--color-orange-light: rgb(255, 153, 0);
	--color-orange-main: #ff4d01;
	--color-orange-secondary: #f04e09;
	--color-orange-faded: #ee6f39;
	--color-orange-error: #e97132;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.container {
	width: 100%;
	max-width: 1360px;
	margin: 0 auto;
	padding: 0;
}

:root {
	--shadow: -4px 5px 5px -3px rgba(0, 0, 0, 0.51);
}
