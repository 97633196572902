.layout {
	display: grid;
	column-gap: 20px;
	margin-bottom: 20px;
}

.layout > :last-child {
	margin-top: 30px;
}

.wrapperGrid {
	display: grid;
	grid-template-columns: 3fr 2fr;
	column-gap: 20px;
	margin-bottom: 12px;
}

.statistics {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	/* margin-top: 20px; */
}

.statisticsItem {
	border: 1px solid rgba(5, 5, 5, 0.06);
	padding: 10px;
	border-radius: 5px;
}

.trustScode {
	margin-top: 52px;
	display: flex;
	justify-content: end;
	width: 100%;
	margin-bottom: 2rem;
}

.scoreLayout {
	border: 1px solid rgba(5, 5, 5, 0.06);
	padding: 10px;
	border-radius: 5px;
	margin-left: 10px;
	width: 370px;
	height: fit-content;
}

.scoreTitle {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 4.6rem;
	row-gap: 10px;

	font-weight: 600;
}

.scoreKey {
	font-size: 12px;
	font-weight: 500;
}

.scoreItem {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	text-transform: capitalize;
	margin: 10px 0;
}

.rate {
	font-size: 12px !important;
	text-align: right;

	display: grid;
	grid-template-columns: 5fr 1fr;
	justify-content: right;
}

.engagement {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 2rem;
}

.stats {
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 20px;
}

.activity {
	/* padding: 20px 0; */
	display: flex;
	align-items: end;
	width: 100%;
	justify-content: space-around;
	border: 1px solid #0505050f;
	/* margin-top: 20px; */
	min-height: 250px;
	border-radius: 5px;
}

.barcontainer {
	/* background-color: #181818; */
	position: relative;
	width: 17%;
	height: 83%;
	border-radius: 5px;
	border-bottom-right-radius: 5px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.bar {
	background-color: #323232;
	border-radius: 10px;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 80%;
	box-sizing: border-box;
	animation: style_grow__e6c04 1.5s ease-out forwards;
	transform-origin: bottom;
	margin-bottom: 34px;
}

.platformIcon {
	position: absolute;
	z-index: 1;
}

@keyframes grow {
	from {
		transform: scaleY(0);
	}
}
