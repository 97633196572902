.tokenTextArea {
	resize: none !important;
	padding-right: 3rem;
}

.buttonsWrapper {
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	flex-direction: column;
	gap: 4px;
}

.listTitle {
	margin-bottom: 0.125rem;
}
