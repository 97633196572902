.title {
	color: var(--color-blue-primary);
	text-decoration: underline var(--color-orange-primary);
	margin: 0;
	margin-bottom: 20px;
	text-align: left;
}

.buttonLinksWrapper {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	/* grid-template-columns: 1fr 1fr; */
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 300px;
	max-width: 920px;
	gap: 60px;
	/* row-gap: 40px; */
	/* column-gap: 20px; */

	margin: 0 auto;

	list-style: none;
}

.listItem {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	border-radius: 24px;

	/* overflow: hidden; */

	width: 100%;
	min-width: 290px;
	max-width: 310px;

	height: 150px;

	cursor: pointer;
}

.link {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	overflow: hidden;
	/* border: 1px solid transparent;
	margin: -1px; */
	border-radius: 24px;
}

.faded {
	border-color: gray !important;
}

.faded img,
.faded .subtitleText {
	filter: grayscale(1);
	color: gray !important;
}

.imageWrapper {
	width: 52%;
	height: 100%;
	overflow: hidden;
	/* min-width: 200px; */
}

.image {
	display: block;
	width: 100%;
	height: 100%;
	/* min-width: 200px; */
	/* aspect-ratio: 9 / 16; */
	object-fit: cover;
}

.textWrapper {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60%;
	height: 100%;
	padding: 0 1rem;
	text-align: center;
}

.text {
	/* text-decoration: none !important; */
	font-size: 1.5rem;
	color: black;
	font-weight: 500;
	line-height: 130%;
	max-width: 125px;
	margin: 0;
}

.subtitleText {
	color: orange;
	text-decoration-color: orange;

	font-weight: 600;
	font-style: italic;
	/* text-decoration: none !important; */
}

.listItem:hover .text,
.listItem:hover .subtitleText {
	text-decoration: underline;
}

/* .link:hover {
	text-decoration: none;
}

.link:hover .subtitleText,
.listItem:hover .subtitleText {
	text-decoration: none;
} */

.alreadySubscribedBadge {
	position: absolute;

	bottom: -12px;
	left: calc((100% + 41%) / 2);

	transform: translateX(-50%);

	padding: 0.125rem 0.5rem;
	width: max-content;

	background-color: gray;
	color: #fff;
	font-size: 0.75rem;
	font-weight: 600;
	font-style: italic;
}

@media screen and (min-width: 1200px) {
	.buttonLinksWrapper {
		padding: 0 120px;
	}
}

@media screen and (min-width: 1400px) {
	.buttonLinksWrapper {
		padding: 0 200px;
	}
}
