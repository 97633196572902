.logo {
	width: 120px;
	display: flex;
	justify-content: center;
	/* width: fit-content; */
}

.logo img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.title {
	font-weight: 700;
	font-size: 24px;
	color: #e97132;
	margin: 0;
}

.underline {
	text-decoration: underline var(--color-orange-light) !important;
}

.topWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.75rem;
	margin-bottom: 0.5rem;
}

.headerTitle {
	color: #156082;
	font-size: 2rem;
	font-weight: 400;
	margin: 0;
}

.imageWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.imageWrapper img {
	max-height: 100%;
}

.textWrapper {
	color: #e97132;
}

.contentWrapper {
	gap: 1rem;
	height: 100%;
}

.textWrapper {
	/* padding: 0.5rem 0.75rem; */
	color: #252525;
}

.heading {
	text-align: left;
	margin-top: 0;
}

.leftSideWrapper,
.rightSideWrapper {
	/* width: calc(40% - 0.5rem); */
	width: 100%;
}

.rightSideWrapper {
	/* width: calc(60% - 0.5rem); */
	align-items: flex-start;
	gap: 1rem;
}

.scrollableWrapper {
	width: 100%;
	overflow-y: auto;
	/* max-height: calc(100vh - 13rem); */
}

.scrollableWrapper2 {
	width: calc(100% - 0.25rem);
	overflow-y: auto;
	max-height: calc(100vh - 13rem);
	height: 100%;
	padding-right: 0.25rem;

	text-align: left;
}

.keyInsights {
	width: 100%;

	text-align: left;
	color: #000 !important;
}

.tabTitle {
	margin-top: 0.5rem !important;
	text-align: left;

	color: var(--color-deep-navy) !important;
}

.statsWrapper {
	width: 100%;
	margin: 1rem auto 0;
}

.statItemTitle {
	display: flex;
	align-items: center;

	margin: 0 !important;
	/* min-height: 28px; */
}

.statItemTitle.flexStart {
	align-items: flex-start;
}

.totalCommentsCount {
	color: var(--color-orange-light);
	text-decoration: none !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.text {
	text-align: left;
}

.thumbnail {
	width: 100px;
	max-width: 100px;
	height: auto;
	object-fit: cover;

	border-radius: 50%;
	overflow: hidden;
}

.amountItem {
	color: #0070c0;
	font-weight: 800;
	font-size: 18px;
}

.amountItemIcon {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.amountItemIcon svg {
	width: 18px;
	height: 18px;

	fill: #ff824a;
}

.videosList {
	margin: 0;
	padding: 0;
	list-style: none;
}

.linkList {
	width: 100%;
	flex-grow: 1;
	margin: 0;
	padding-left: 0;
	list-style-type: none !important;
}

.linkListItem {
	width: auto;
	font-size: 14px;
}

.linkListItem a {
	width: fit-content;
	max-width: calc(100% - 30px);
	display: block;
	word-wrap: break-word;
	overflow: hidden;
}

.mentions a,
.linkListItem a {
	color: var(--color-blue-link);
	font-size: 14px;
}

.mentions a:hover,
.linkListItem a:hover {
	color: var(--color-blue-link-hover);
}

.highlightedItem {
	font-size: 22px;
}

.mentionSign {
	display: inline-block;
	font-size: 18px;
	min-width: 20px;
	margin-right: 5px;
	color: var(--color-gray);
}

.metaIcon {
	margin-right: 0.5rem;
	color: var(--color-gray);
	align-items: flex-start;
}

.mentionSign.available,
.metaIcon.available {
	color: var(--color-blue);
}

.metaText {
	font-size: 16px;
	font-weight: 600;
	color: var(--color-gray);
}

.metaText.small {
	font-size: 14px;
}

.metaText.available {
	color: var(--color-orange-light);
}

.metaText.tags {
	font-weight: 400;
	color: var(--color-gray);
}

.thin {
	font-weight: 400;
}

.searchWrapper {
	position: relative;
	margin-bottom: 0.5rem;
}

.commentsWrapper {
	width: 100%;
	margin: 0 auto;
}

.searchInput {
	padding-right: 1.5rem;
}

.searchIcon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.grayText {
	color: gray;
}

.actionsTitle {
	color: var(--color-orange-light);
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 12px;
}

.actionsIcon svg {
	width: 18px;
	height: 18px;
}

.actionsList,
.keyInsights {
	width: 100%;

	text-align: left;
	color: #000 !important;
}

.noIssuesLabel {
	padding-left: 1rem;
	font-weight: 500;
}

.noIssuesIcon {
	color: var(--color-deep-navy);
}

.brandEntityTitle {
	color: var(--color-deep-navy) !important;
	margin-top: 0.25rem;
}

.actionsList {
	padding-left: 1rem;
}
