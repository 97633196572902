.root {
	display: flex;
	flex-direction: column;
}

span.description {
	font-size: 11px;
	color: grey;
}

.badges {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;
	row-gap: 20px;
}

.contentGaps {
	display: flex;
	align-items: center;
	font-weight: 500;
	margin-bottom: 30px;
}

.contentGaps img {
	margin-right: 20px;
}

.garmHeader {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
}

.garmHeaderText {
	display: flex;
	align-items: center;
	gap: 10px;
	text-decoration: underline;
	color: black;
}

.tableContainer {
	position: relative;
	overflow: hidden;
	text-align: center;
}

.ant-table-cell {
	vertical-align: top;
  }