/* .subscription-wrapper .radio-custom .ant-radio-wrapper {
	border: 2px solid #ccc;
	border-radius: 8px;
	padding: 4px;
}

.subscription-wrapper .radio-custom .ant-radio-wrapper-checked {
	border: 2px solid blue;
	border-radius: 8px;
	padding: 4px;
}

.subscription-wrapper .radio-custom .ant-radio {
	position: absolute;
	left: -9999px;
	overflow: hidden;
}

.subscription-wrapper .radio-custom .ant-radio + * {
	display: block;
	padding: 0;
	overflow: hidden;
	border-radius: 8px;
	overflow: hidden;
} */

.subscription-wrapper .ant-card {
	text-align: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	transition: all 0.3s ease-in-out; /* Add transition for smooth animation */
}

.subscription-wrapper .ant-card:hover {
	/* color: white;
	background-color: black; */
	transform: scale(1.05); /* Scale the element up to 105% */
	box-shadow: 0 7px 2px 0 rgba(249, 147, 31, 0.05),
		0 15px 6px -1px rgba(206, 18, 18, 0.02), 0 2px 4px 0 rgba(218, 15, 15, 0.02) !important;
}
.subscription-wrapper .ant-card-body {
	flex-grow: 1;
}

.subscription-wrapper .ant-card-head {
	font-size: 1.5rem;
}
