.wrapper {
	width: 100%;
	height: 100vh;
	background: #eee;
	padding-top: 60px;
}

.title {
	color: var(--color-blue-primary) !important;
	text-align: center;
}

.backButton {
	margin-top: 1.5rem;
}
