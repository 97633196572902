.logo {
	display: flex;
	justify-content: center;
	margin: 8px 0;
}

.logo img {
	width: 95px;
	height: auto;
}

.title h2 {
	font-weight: 700;
	font-size: 16px;
}
