.wrapper {
	display: block;
}

.button {
	background-color: transparent;
	padding: 8px 12px;
}

.image {
	width: 28px;
	height: 28px;
}
