.navbar {
	position: relative;
	margin-bottom: 3rem;
}
.layout {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
}

.layout .left {
	display: flex;
	align-items: center;

	column-gap: 2rem;
}

.layout .left .menu,
.layout .right .menu {
	display: flex;
	column-gap: 30px;
}

.menu span {
	cursor: pointer;
}

.logo img {
	width: 75px;
	height: auto;
}

.gradient {
	position: absolute;
	left: -100px;
	bottom: -20px;
}
.gradient img {
	width: 100%;
	height: auto;
}

.icon:hover {
	color: #4fbaed;
}

.center {
	margin-right: 400px;
}

.pageTitle {
	color: var(--color-blue-primary);
	/* text-decoration: underline var(--color-orange-primary); */
	margin: 0;
	text-align: left;
}
