.image {
  width: 60px;
  height: 50px;
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;

  margin: 0.5rem 0 0 !important;

  color: var(--color-deep-navy) !important;
}
