.title {
	color: var(--color-blue-primary);
	text-decoration: underline var(--color-orange-primary);
	margin: 0;
	margin-bottom: 20px;
	text-align: left;
}

.buttonLinksWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 70%;
	min-width: 300px;
	gap: 60px;

	margin: 0 auto;

	list-style: none;
}

.link {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	border-radius: 24px;

	overflow: hidden;

	width: calc(50% - 60px);
	min-width: 290px;
	max-width: 310px;

	height: 150px;
	text-decoration: none;
}

.imageWrapper {
	width: 52%;
	height: 100%;
	overflow: hidden;
	/* min-width: 200px; */
}

.image {
	display: block;
	width: 100%;
	height: 100%;
	/* min-width: 200px; */
	/* aspect-ratio: 9 / 16; */
	object-fit: cover;
}

.textWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60%;
	padding: 0 1rem;
	text-align: center;
}

.text {
	text-decoration: none !important;
	font-size: 1.5rem;
	color: black;
	font-weight: 500;
	max-width: 125px;
}

.button {
	padding: 0 !important;
	gap: 0;
	white-space: wrap;
	line-height: normal;
}

.link:hover {
	text-decoration: underline;
}

.gray {
	filter: grayscale(1);
}
