.videoInfo {
	color: black;
	font-size: 12px;
	margin-top: 5px;
}

.videoStats {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
}

.videoWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: 100%;
}

.videoWrapper .title {
	color: black;
	font-size: 11px;
	line-height: 0.9;
	font-weight: 600;
}
