.view {
	width: 100%;
	min-height: 100vh;
	height: auto;

	/* css background image */
	background-image: url('../../lib/assets/backgroung.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position-x: left;
	background-position-y: center;
	background-size: cover;

	display: grid;
	grid-template-columns: 3fr 8fr;
	column-gap: 2rem;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-content: center;
}

.form {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	background-image: linear-gradient(
		to right,
		rgb(0 0 0 / 1%),
		rgb(0 0 0 / 76%)
	);
	min-height: 100vh;
}

.trust {
	top: 4rem;
	left: 4rem;
	width: 25%;
	position: fixed;
}
