.card {
	border: 1px dashed rgb(0, 187, 237);
	border-radius: 8px;
	padding: 12px;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;

	display: flex;
	flex-direction: column;

	gap: 20px;
}

.cardInput {
	display: flex;
	justify-content: center;
	align-items: center;

	gap: 20px;
}

.cardView {
	display: grid;
	align-items: center;
	/* padding: 5px 0; */
	/* margin: 10px 0; */

	grid-template-columns: 2fr 3fr 50px;
}

.delete {
	display: flex;
	justify-content: flex-end;
}

.controls {
	display: flex;
	justify-content: space-between;
}
