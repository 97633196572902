.layout {
	display: grid;
	grid-template-columns: minmax(150px, 1fr) 5fr;

	height: 100%;
	column-gap: 2rem;
}

.content {
	/* line-height: 260px; */
	/* text-align: center; */
	color: rgba(0, 0, 0, 0.45);
	background-color: rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	border: 1px dashed rgb(217, 217, 217);
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	/* padding: 1rem; */
}

.form {
	height: 100%;
	/* padding: 1rem; */
}

.invite_emails {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 16px;
	row-gap: 0;
}
