.root {
	display: grid;
	grid-template-columns: 2fr 5fr;
	column-gap: 10px;
	height: 100;
}

.avatarContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: space-between; */
}

h3 {
	text-align: center;
}

.avatar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.socialMedia {
	display: flex;
	column-gap: 10px;
}

.descriptionContainer {
}

.statistics {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-top: 20px;
}

.statisticsItem {
	border: 1px solid rgba(5, 5, 5, 0.06);
	padding: 10px;
	border-radius: 5px;
}
