.siteCardWrapper {
	width: 100%;
	/* max-width: 1100px; */
	margin: 66px 0 0 0;
}
.andtRow {
	gap: 10px;
	padding: 0 25px;
	/* height: 70vh; */
	align-items: stretch;
	justify-content: center;
}
.andtCard {
	text-align: center;
	border: 1px solid #e3dcdc;
	height: 100%;
	box-shadow: 0 7px 2px 0 rgba(8, 189, 238, 0.05),
		0 15px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
}

.andtCardUnsubscribe {
	text-align: center;
	border: 1px solid #e3dcdc;
	height: 100%;
	box-shadow: 0 0 20px 5px rgb(22, 122, 255) !important;
}

.andtForm {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.includes {
	display: flex;
}

.checkicon {
	display: flex;
	margin-right: 10px;
	flex-direction: column;
	margin: 5px 10px 0 0;
}

.andtFormHorizontal {
	display: flex;
	flex-direction: row;
}
.round {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	font-size: 30px;
	float: left;
	font-weight: bold;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: green;
	color: white;
}
