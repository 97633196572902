.reviewSelection {
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-end;
}

.influencerGrid {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.influencer {
	padding: 20px;
	border: 1px dashed #00bbed;
	border-radius: 10px;

	display: flex;
	align-items: center;
}

.form {
	display: flex;
	gap: 30px;
}

.influencer .card {
	width: 100%;

	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 100px;
	column-gap: 30px;
}

.influencer .card .platforms {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.influencer .card .platforms .stats {
	display: flex;
	align-items: center;
	gap: 20px;
}

.influencer-card-social-item {
	display: flex;
	flex-direction: row;
}
/*  */

.influencerSearchForm {
	display: flex;
	flex-direction: column;
}

.influencerv3 {
	border-radius: 8px;
	padding: 20px 8px;
	margin: 20px 0;
}

lightBorder {
	border: 1px solid rgb(217, 217, 217);
}
socialLinksInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
}
