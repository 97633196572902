.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.avatar {
	display: flex;
	/* justify-content: center; */
}

.link {
	color: var(--color-blue-primary);
	text-decoration: underline var(--color-orange-primary);
}

h3.link {
	margin: 0;
	margin-bottom: 20px;
	text-align: left;
}

.layout {
	display: grid;
	grid-template-columns: 200px 1fr;
	column-gap: 20px;
}

.creatorHeader {
	display: flex;
	/* align-items: flex-end; */
	align-items: center;
	/* justify-content: center; */
	justify-content: space-between;
}

.creatorName {
	font-weight: bold;
	font-size: 30px;
}

.reportSection {
	/* height: 100vh; */
	padding: 2rem 0;
	/* margin: 2rem 0; */
}

.badge {
	color: var(--color-orange-primary);
	font-weight: 600;
}

.paragraphs {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 20px;
}

.cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 2rem;
}

.card {
	padding: 10px;
	display: flex;
	flex-direction: column;
	border: 2px solid var(--color-orange-primary);
	border-radius: 10px;
}

.cardTitle {
	color: #1b4fd3;
	text-align: center;
	font-weight: 500;
	font-size: 18px;
}

.cardContent {
	display: flex;
	justify-content: space-around;
	text-align: center;
	margin-top: 10px;
}

.contentValue {
	font-weight: 500;
	font-size: 18px;
}

.iabLogo {
	position: absolute;
	top: 0;
	right: 0;
}

.topicDriftGrid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 40px;
	row-gap: 40px;
}

.imageContainer {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.imageContainer img {
	height: auto;
	width: 100%;
}

.empty {
	padding-top: 50px;
}
