.root {
	/* box-shadow: var(--shadow); */
	/* box-shadow: var(--shadow); */
	/* border-radius: 5px; */
}

.headerActions {
	/* display: flex;
	justify-content: flex-end;
	margin: 10px 0; */
}

.searchBar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 15px;
}

.row {
	display: flex;
	gap: 2.6rem;
}

.customerName {
	color: #000000 !important;
	margin: 0 !important;
	font-weight: 300 !important;
	padding: 0 5px !important;
}
