.stripeFormWrapper {
	/* margin-top: 20rem; */
	width: 100%;
	max-width: 400px;
}

.stripeSubmitButton {
	width: 100%;
	margin-top: 24px;
	height: 44px;
}
